@font-face {
  font-family: 'Aspekta';
  src: local(''),
    url('../../../../assets/Font/Aspekta-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Aspekta-bold';
  src: local(''),
    url('../../../../assets/Font/Aspekta-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Aspekta-medium';
  src: local(''),
    url('../../../../assets/Font/Aspekta-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Aspekta-400';
  src: local(''),
    url('../../../../assets/Font/Aspekta-400.ttf') format('truetype');
}
