@import './assets/Style/Variable.css';
@import './assets/Style/main.css';

* {
  margin: 0;
  font-family: var(--font-regular) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #36285b;
  border-radius: 20px;
}
