.campaign-ls-rows {
  background-color: rgba(60, 60, 60, 0.05);
  cursor: pointer;
  border-radius: 1rem;
  padding: 0.125rem;
  margin-top: 1rem;
  border-bottom: none !important;
  transition: transform 0.5s ease;
}

.campaign-ls-rows:hover {
  transform: scale(1.005);
}

.no-borders {
  border: none !important;
}

.main-campaign-ls .MuiDataGrid-root {
  border: none;
}

.main-campaign-ls .MuiDataGrid-columnHeaders {
  border: none;
}

.main-campaign-ls div div div div > .MuiDataGrid-cell {
  border-bottom: none !important;
}

.campaign-name-col .icon {
  height: 1.25rem;
  width: 1.25rem;
  padding-right: 1.25rem;
  border: 0.0313rem solid grey;
  margin-right: 1.25rem;
  border-radius: 0.25rem;
}

.campaign-name-col .heading {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #181f28;
}

.campaign-name-col .subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #616161;
}

.campaign-name-col .text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: #181f28;
}

.status-pill {
  align-items: center;
  padding: 4px 8px;
  border-radius: 12px;
  color: white;
}

.status-not-approved {
  background-color: #a91919;
}
.status-approved {
  background-color: #26672d;
}
.status-pending {
  background-color: #c9a907;
}
.status-draft {
  background-color: #156b9d;
}

.campaign-actions-icon {
  color: #ff7b01;
}

/* Campaign Summary Card */
.campaign-summary-card-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.625rem;
  padding: 0.75rem;
  margin: 0rem;
}

.campaign-summary-card-container .image-and-details-container {
  display: flex;
  flex-direction: row;
  gap: 1.0625rem;
}

.campaign-summary-card-container .image-container {
  height: 4rem;
  width: 4rem;
  border: 0.0313rem #e1e1e1;
  border-radius: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaign-summary-card-container .image-container .image {
  height: 90%;
  width: 90%;
  object-fit: cover;
}
