@font-face {
  font-family: 'GoogleSansBold';
  src: url('../Font/GoogleSans-Bold-v1.27.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GoogleSansMedium';
  src: url('../Font/GoogleSans-Medium-v1.27.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'GoogleSansRegular';
  src: url('../Font/GoogleSans-Regular-v1.27.ttf') format('truetype');
  /* font-weight: 400; */
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ABeeZeeRegular';
  src: url('../Font/ABeeZee-Regular.ttf') format('truetype');
  /* font-weight: 400; */
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'ABeeZeeItalic';
  src: url('../Font/ABeeZee-Italic.ttf') format('truetype');
  /* font-weight: 400; */
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Aspekta-300';
  src: local(''), url('../Font/Aspekta-300.ttf') format('truetype');
}
@font-face {
  font-family: 'Aspekta-350';
  src: local(''), url('../Font/Aspekta-350.ttf') format('truetype');
}
@font-face {
  font-family: 'Aspekta-400';
  src: local(''), url('../Font/Aspekta-400.ttf') format('truetype');
}
@font-face {
  font-family: 'Aspekta-450';
  src: local(''), url('../Font/Aspekta-450.ttf') format('truetype');
}
@font-face {
  font-family: 'Aspekta-500';
  src: local(''), url('../Font/Aspekta-500.ttf') format('truetype');
}
@font-face {
  font-family: 'Aspekta-600';
  src: local(''), url('../Font/Aspekta-600.ttf') format('truetype');
}
@font-face {
  font-family: 'Aspekta-700';
  src: local(''), url('../Font/Aspekta-700.ttf') format('truetype');
}

:root {
  --primary: #4785ff;
  --danger: #ff4747;
  --font-regular: 'GoogleSansRegular';
  --font-Aspekta: 'Aspekta';
  --font-Aspekta-300: 'Aspekta-300';
  --font-Aspekta-350: 'Aspekta-350';
  --font-Aspekta-400: 'Aspekta-400';
  --font-Aspekta-450: 'Aspekta-450';
  --font-Aspekta-500: 'Aspekta-500';
  --font-Aspekta-600: 'Aspekta-600';
  --font-Aspekta-700: 'Aspekta-700';
  --font-color-regular: '#3A4A5F';
}
